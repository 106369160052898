<template>
  <div class="div-padding pt-5 pt-lg-0 hero-images wrapper" id="faq-pg">
    <b-container fluid class="pt-5 pb-5">
      <b-row align-h="center" class="" style="margin-top: 15%">
        <span class="text-1 mt-0">Frequently Asked Questions</span>
      </b-row>
    </b-container>
    <div class="faq-tabs-wrapper mb-5">
      <ul class="faq-tabs pl-0 d-md-flex justify-content-center text-md-center">
        <li
          class="faq-tab py-2"
          @click.stop.prevent="generalOnClick()"
          :class="{ faqSelected: generalSelected }"
        >
          <h6>General</h6>
        </li>
        <li
          class="faq-tab py-2"
          @click.stop.prevent="tPayOnClick()"
          :class="{ faqSelected: tPaySelected }"
        >
          <h6>t-Pay</h6>
        </li>
        <li
          class="faq-tab py-2"
          @click.stop.prevent="tVerifyOnClick()"
          :class="{ faqSelected: tVerifySelected }"
        >
          <h6>t-Verify</h6>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../assets/styles/all.scss";
//@import "../../assets/styles/reset.css";

.faq-tabs {
  list-style-type: none;
  cursor: pointer;
  h6 {
    color: $gray-700;
  }
}
.faqSelected {
  h6 {
    color: $color-primary !important;
  }
  border-bottom: 4px solid $color-primary !important;
}

.faq-tab {
  border-bottom: 4px solid $gray-500;
}

@media screen and (min-width: 768px) {
  .faq-tab {
    width: 220px;
  }
}

$large: 992px;
.hero-images {
  background-image: url("../../assets/img/dots.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;

  @media screen and (min-width: $large) {
    // background-position: center;
    // background-size: cover;
    // position: relative;
    max-height: 500px;
  }
}
.div-padding {
  padding: 0% 5%;
}

.text-1 {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 69px;
  letter-spacing: 0.05em;
  color: #4f4f4f;
}

.text-2 {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #4f4f4f;
  @media screen and (min-width: $large) {
    width: 50%;
    margin: 0 auto;
  }
}

.text-3 {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 69px;
  letter-spacing: 0.05em;
  color: #4f4f4f;
}

.button_learn {
  @media screen and (max-width: 576px) {
    text-align: left;
    margin-left: -75%;
  }
}

.circle {
  border: 1.5px solid #8a8a8a;
}
</style>

<script>
export default {
  data() {
    return {
      tPaySelected: false,
      tVerifySelected: false,
      generalSelected: true,
    };
  },
  // props: ["generalSelected", "tPaySelected", "tVerifySelected"],
  methods: {
    tPayOnClick() {
      if (!this.tPaySelected) {
        this.tPaySelected = true;
        this.tVerifySelected = false;
        this.generalSelected = false;
        this.$emit("tPayOnClick");
      }
      return;
    },
    tVerifyOnClick() {
      if (!this.tVerifySelected) {
        this.tPaySelected = false;
        this.tVerifySelected = true;
        this.generalSelected = false;
        this.$emit("tVerifyOnClick");
      }
      return;
    },
    generalOnClick() {
      if (!this.generalSelected) {
        this.tPaySelected = false;
        this.tVerifySelected = false;
        this.generalSelected = true;
        this.$emit("generalOnClick");
      }
      return;
    },
  },
};
</script>
