<template>
  <div class="accordion" role="presentation">
    <accordionItem
      v-for="item in content"
      :multiple="multiple"
      :item="item"
      :groupId="groupId"
      :key="item.id"
    >
    </accordionItem>
  </div>
</template>
<style lang="scss">
@import "../../assets/styles/all.scss";
//@import "../../assets/styles/reset.css";
button:hover {
  box-shadow: none;
}
button {
  border-radius: 0;
}
</style>
<script>
import accordionItem from "./FaqAccordionItem.vue";
export default {
  name: "accordion",
  components: {
    accordionItem,
  },
  props: {
    content: {
      type: Array,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      groupId: null,
    };
  },
  mounted() {
    this.groupId = this.$el.id;
  },
};
</script>
