<template>
  <div class="section" id="faq-accordion">
    <div role="presentation" class="accordion box" id="accordion-example-2">
      <div class="accordion-wrapper" v-for="item in tverifyQA" :key="item.id">
        <h6>{{ item.category }}</h6>
        <accordion
          id="accordion-example-2"
          :content="item.faq"
          multiple
          class="mb-5"
        ></accordion>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
$browser-context: 16;
$blue: #1779ba;
$gray: #cacaca;
$red: #cc4b37;
$white: #ffffff;

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

#app {
  margin: em(60) auto;
  padding: em(12);
  max-width: em(800);
}

#faq-accordion {
  max-width: 1100px;
  margin: 0 auto;
}

.container {
  margin-bottom: em(24);
}

.btn-action-delete {
  color: $red;
  cursor: pointer;
}

.accordion {
  padding: 0;
  dd {
    margin-left: 0;
  }
}

.accordion-wrapper {
  margin: 0 1rem;
}
</style>

<script>
import tverifyQA from "../../assets/json/faqTverify.json";
import accordion from "./FaqAccordion.vue";

export default {
  name: "faq",
  components: {
    accordion,
  },
  data() {
    return {
      tverifyQA: tverifyQA,
    };
  },
  methods: {
    toggle(event) {
      if (this.multiple) this.item.active = !this.item.active;
      else {
        this.$parent.$children.forEach((item) => {
          if (
            item.$el.id === event.currentTarget.parentElement.parentElement.id
          )
            item.item.active = !item.item.active;
          else item.item.active = false;
        });
      }
    },

    startTransition(el) {
      el.style.height = el.scrollHeight + "px";
    },

    endTransition(el) {
      el.style.height = "";
    },
  },
};
</script>
