<template>
  <div
    :id="groupId + '-' + item.id"
    class="accordion-item"
    :class="{ 'is-active': item.active }"
  >
    <dt class="accordion-item-title">
      <button
        @click="toggle"
        class="accordion-item-trigger d-flex justify-content-between"
      >
        <h6 class="accordion-item-title-text">{{ item.title }}</h6>
        <img
          class="accordion-item-trigger-icon-collapse"
          src="../../assets/img/faq-collapse.svg"
          alt=""
        />
        <img
          class="accordion-item-trigger-icon-expand"
          src="../../assets/img/faq-expand.svg"
          alt=""
        />
      </button>
    </dt>
    <transition
      name="accordion-item"
      @enter="startTransition"
      @after-enter="endTransition"
      @before-leave="startTransition"
      @after-leave="endTransition"
    >
      <dd v-if="item.active" class="accordion-item-details dd">
        <div v-html="item.details" class="accordion-item-details-inner"></div>
      </dd>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
@import "../../assets/styles/all.scss";
//@import "../../assets/styles/reset.css";
.dd {
  margin-bottom: 0rem !important;
}
.accordion-item-details-inner {
  border-top: 1px solid $color-border;
  padding-top: 0.75rem;
}

.accordion-item-details-inner p {
  color: $color-text800 !important;
}
.accordion-item h6 {
  color: $color-text900;
}

.accordion-item-trigger-icon-collapse {
  display: none;
  .is-active & {
    display: block;
  }
}

.accordion-item-trigger-icon-expand {
  .is-active & {
    display: none;
  }
}

.accordion-item {
  border: 1px solid $color-border;
  margin-bottom: 1rem;
  margin-top: 2rem;
  border-radius: 4px;
}

.accordion-item-details {
  padding: 0rem 1rem 0.75rem 1rem;
}
.accordion-item-trigger {
  padding: 0.75rem 1rem;
}
@media screen and (min-width: 768px) {
}

.accordion-item-title {
  position: relative;
}

.accordion-item-title {
  .is-active & {
    background-color: $color-bg-secondary;
  }
}

.accordion-item-trigger {
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
  align-items: center;
}

.accordion-item-trigger-icon {
  // $size: 8px;
  // display: block;
  // position: absolute;
  // top: 0;
  // right: 1.25rem;
  // bottom: 0;
  // margin: auto;
  // width: $size;
  // height: $size;
  // border-right: 2px solid #363636;
  // border-bottom: 2px solid #363636;
  // transform: translateY(-$size / 4) rotate(45deg);
  // transition: transform 0.2s ease;

  // .is-active & {
  //   transform: translateY($size / 4) rotate(225deg);
  // }
}

.accordion-item-details {
  overflow: hidden;
  background-color: $color-bg-secondary;
}

button {
  margin-top: 0;
}

.accordion-item-enter-active,
.accordion-item-leave-active {
  will-change: height;
  transition: height 0.2s ease;
}
.accordion-item-enter,
.accordion-item-leave-to {
  height: 0 !important;
}
</style>

<script>
export default {
  name: "accordion-item",
  props: ["item", "multiple", "groupId"],
  methods: {
    toggle(event) {
      if (this.multiple) this.item.active = !this.item.active;
      else {
        this.$parent.$children.forEach((item) => {
          if (
            item.$el.id === event.currentTarget.parentElement.parentElement.id
          )
            item.item.active = !item.item.active;
          else item.item.active = false;
        });
      }
    },

    startTransition(el) {
      el.style.height = el.scrollHeight + "px";
    },

    endTransition(el) {
      el.style.height = "";
    },
  },
};
</script>
