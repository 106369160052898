<template>
  <div class="faq">
    <FaqDashboard
      @generalOnClick="generalOnClick()"
      @tPayOnClick="tPayOnClick()"
      @tVerifyOnClick="tVerifyOnClick()"
    />
    <FaqGeneral class="faqHide" :class="{ faqSelected: generalSelected }" />
    <FaqTpay class="faqHide" :class="{ faqSelected: tPaySelected }" />
    <FaqTverify class="faqHide" :class="{ faqSelected: tVerifySelected }" />
  </div>
</template>

<style lang="scss" scoped>
.faqSelected {
  display: block !important;
}
.faqHide {
  display: none;
}
</style>

<script>
import FaqDashboard from "../components/FaqComponents/FaqDashboard.vue";
import FaqGeneral from "../components/FaqComponents/FaqGeneral.vue";
import FaqTpay from "../components/FaqComponents/FaqTpay.vue";
import FaqTverify from "../components/FaqComponents/FaqTverify.vue";

export default {
  name: "faq",
  data() {
    return {
      tPaySelected: false,
      tVerifySelected: false,
      generalSelected: true,
      // tabContents: [
      //   {
      //     tab: "Identity Verification",
      //     title: "SEE THE REAL IDENTITY.",
      //     description:
      //       "Create trusted user identifies with <span style='color:#FF3377'>t-VerifyID</span>. Scan user ID<br>photo from any device, before checking it's genuine.<br>Combine with <span style='color:#FF3377'>t-VerifyFace</span>. Add liveness detection to do a<br>face match and protect against impersonation.",
      //     images: ["selfie.png", "verify-document.png"],
      //     btntitle: "Explore t-Verify",
      //     scrollto: "'#tverifyid'",
      //   },
      //   {
      //     tab: "Online Payments",
      //     title: "ACCEPT PAYMENTS ONLINE.",
      //     description:
      //       "A simple way to accept payments online,<br>in-app and on your website.",
      //     images: ["checkout.png", "payment_successful.png"],
      //     btntitle: "Loop me in",
      //     scrollto: "'#tverifyface'",
      //   },
      // ],
    };
  },
  methods: {
    tPayOnClick() {
      this.tPaySelected = true;
      this.tVerifySelected = false;
      this.generalSelected = false;
    },
    tVerifyOnClick() {
      this.tPaySelected = false;
      this.tVerifySelected = true;
      this.generalSelected = false;
    },
    generalOnClick() {
      this.tPaySelected = false;
      this.tVerifySelected = false;
      this.generalSelected = true;
    },
  },
  components: {
    FaqDashboard,
    FaqGeneral,
    FaqTpay,
    FaqTverify,
  },
  metaInfo() {
    return {
      title: "Powering digital payments and identity verification",
      meta: [
        {
          name: "Powering digital payments and identity verification",
          content:
            "Accept online payments with card, FPX and e-Wallet in less than a minute using Tekkis no-code solutions. No Tech team or website needed. Tekkis also offers API integrations for both eKYC and online payments.",
        },
        {
          property: "og:title",
          content: "Powering digital payments and identity verification",
        },
        { property: "og:site_name", content: "Tekkis" },
        { property: "og:type", content: "website" },
      ],
    };
  },
};
</script>
